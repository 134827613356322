import ApiService from "@/core/services/api.service";

// const moment = require('moment');
const querystring = require('querystring');

const QUOTE_ENDPOINT = "/api/v1/protection/quote";
const APPLY_ENDPOINT = "/api/v1/protection/apply";
const POSTCODE_ADDRESS_ENDPOINT = "/api/v1/protection/postcode-address";

const getDefaultState = () => {
  return {
    quoteForm: {
      primaryMember: {
          title: "mr",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          telephoneNumber: "",
          landlineNumber: "",
          email: "",
          gender: "male",
          maritalStatus: "single",
          postcode: "",
          address1: "",
          address2: "",
          town: "",
          county: "",
          occupation: "",
          annualEarnings: null,
          isSmoker: 0,
          pastOngoingCondition: 0,
          riskOccupation: 0,
          heightFeet: null,
          heightInch: null,
          heightM: null,
          weightKg: null,
          weightSt: null,
          weightLbs: null
      },
      secondaryMember: {
          title: "mr",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          email: "",
          gender: "male",
          maritalStatus: "single",
          occupation: "",
          annualEarnings: null,
          isSmoker: 0,
          pastOngoingCondition: 0,
          riskOccupation: 0,
          heightFeet: null,
          heightInch: null,
          heightM: null,
          weightKg: null,
          weightSt: null,
          weightLbs: null
      },
      hasWaiverPremium: false,
      hasIndexation: false,
      quoteType: "family",
      policyType: "single",
      drivenType: "benefit",
      benefitType: "death",
      deathBenefitAmount: null,
      criticalIllnessAmount: null,
      premium: null,
      term: null,
      paymentMode: "monthly",
      medicalHistoryOne: [],
      medicalHistoryTwo: [],
      hazardousPursuit: "",
      travelledAbroad: "",
      additionalInformation: ""
    },
    mainQuoteRef: "",
    editQuoteRef: "",
    mainRqQuoteRef: "",
    editRqQuoteRef: "",
    displayMode: "live",
    isLoading: false,
    isEditMode: false,
    isViewMode: false,
    sortOrder: 'asc',
    sortBy: 'price',
    
    protectionQuoteResults: [],
    quoteErrors: [],
    selectedProduct: {},
    quoteApplicationStep: "agreement",
    quotePaymentStep: "confirm",
    quoteApplication: {
      rqQuoteRefId: "",
      quoteRefId: "",
      productRefId: "", 
      isInformationCorrect: false,
      accountName: "",
      accountNumber: "",
      sortCode: ""
    },
    applicationDetails: {
    },
    addresses: [],
    selectedAddress: {},
  }
}

const state = getDefaultState();

const actions = {
  getProtectionQuoteProducts(context) {
    return new Promise((resolve, reject) => {
      if(state.displayMode == "demo"){
        var quotes = require('../../../../demoData/products.json');
        resolve();
        context.commit("updateProtectionMainQuoteRef", "demo123456");
        context.commit("updateProtectionMainRqQuoteRef","1234566789")
        context.commit("updateProtectionQuoteResult", quotes);
        context.commit("updateQuoteErrors", []);
      } else {

        var authState = context.rootState.auth;

        ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
        ApiService.post(QUOTE_ENDPOINT, state.quoteForm)
          .then(res => {
            console.log(res.data);

            context.commit("updateProtectionMainQuoteRef", res.data.quoteRef);
            context.commit("updateProtectionMainRqQuoteRef", res.data.rqQuoteRef);
            context.commit("updateProtectionQuoteResult", res.data.quoteResult);
            context.commit("updateQuoteErrors", res.data.quoteErrors);

            console.log(res.data.quoteResult);
            
            resolve(res.data.quoteResult);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      }
    });
  },
  applyProtectionProduct() {
    return new Promise((resolve, reject) => {
      if(state.displayMode == "demo"){
        resolve({succes:true});
      } else {
        console.log(state.quoteApplicationStep);
        var applicationData = {
          applicationDetails: state.applicationDetails,
          quoteApplication: Object.assign({}, state.quoteApplication, {
            selectedPrice: state.selectedProduct.Premium
          })
        }

        console.log(applicationData);
        ApiService.post(APPLY_ENDPOINT, applicationData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      }
      
    });
  },
  getPostcodeAddress(context) {
    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      var params = { postcode: state.quoteForm.primaryMember.postcode }
      
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(POSTCODE_ADDRESS_ENDPOINT, "?"+querystring.encode(params))
        .then(res => {
          context.commit("updateAddresses", res.data.addresses);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getPreviousProtectionQuote(context, rqQuoteRef) {
    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      var params = { rqQuoteRef: rqQuoteRef };

      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(QUOTE_ENDPOINT, "?"+querystring.encode(params))
        .then(res => {
          console.log(res);
          
          
          context.commit("updateProtectionMainQuoteRef", res.data.quoteRef);
          context.commit("updateProtectionMainRqQuoteRef", res.data.rqQuoteRef);
          context.commit("updateProtectionQuoteResult", res.data.results);

          var quoteForm = res.data.quote;
          // quoteForm.primaryMember.dateOfBirth = moment(quoteForm.primaryMember.dateOfBirth).format("YYYY-MM-DD");

          // if(quoteForm.secondaryMember.dateOfBirth){
          //   quoteForm.secondaryMember.dateOfBirth = moment(quoteForm.secondaryMember.dateOfBirth).format("YYYY-MM-DD");
          // }

          context.commit("updateQuoteForm", quoteForm);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
};

const mutations = {
  updateQuoteForm(state, quoteForm) {
    state.quoteForm = quoteForm;
  },
  updateProtectionQuoteResult(state, protectionQuoteResult) {
    state.protectionQuoteResult = protectionQuoteResult;
  },
  updateLoadingStatus(state, loadingStatus){
    state.isLoading = loadingStatus;
  },
  updateEditMode(state, editMode){
    state.isEditMode = editMode;
  },
  updateViewMode(state, viewMode){
    state.isViewMode = viewMode;
  },
  updateQuoteErrors(state, quoteErrors){
    state.quoteErrors = quoteErrors;
  },
  updateSelectedProtectionProduct(state, product){

    console.log(product);
    state.quoteApplicationStep = "agreement";
    state.quotePaymentStep = "confirm";
    state.quoteApplication.hasReadQuote = false;
    state.quoteApplication.confirmNoAdvice = false;
    state.quoteApplication.hasApplicationDetails = false;
    state.quoteApplication.isInformationCorrect = false;
    state.quoteApplication.accountName = "";
    state.quoteApplication.accountNumber = "";
    state.quoteApplication.sortCode = "";
    state.selectedProduct = product;
    state.quoteApplication.productRefId = product._id;

    console.log(state.quoteApplication);
  },
  updateProtectionQuoteApplication(state, quoteApplication){
    state.quoteApplication = quoteApplication;
  },
  updateProtectionApplicationStep(state, step){
    state.quoteApplicationStep = step;
  },
  updateProtectionPaymentStep(state, step){
    state.quotePaymentStep = step;
  },
  updateProtectionApplicationDetails(state, applicationDetails){
    state.applicationDetails = applicationDetails;
  },
  resetState(state){
    Object.assign(state, getDefaultState());
  },
  updateDisplayMode(state, displayMode){
    state.displayMode = displayMode;
  },
  updateSortBy(state, sortBy){
    state.sortBy = sortBy;
  },
  updateSortOrder(state, sortOrder){
    state.sortOrder = sortOrder;
  },
  updateHasApplicationDetails(state, hasApplicationDetails){
    state.quoteApplication.hasApplicationDetails = hasApplicationDetails;
  },
  sortQuotes(state){
    state.quoteResults = state.quoteResults.sort((a,b) => {
      if(state.sortBy === 'price'){
        if(state.sortOrder === 'asc'){
          return a.selectedPrice - b.selectedPrice;
        } else {
          return b.selectedPrice - a.selectedPrice;
        }
      } else {
        if(state.sortOrder === 'asc'){
          if(a.companyName > b.companyName){
            return 1;
          } else if(a.companyName < b.companyName) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if(a.companyName > b.companyName){
            return -1;
          } else if(a.companyName < b.companyName) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    });
  },
  updateAddresses(state, addresses){
    state.addresses = addresses;
  },
  updateSelectedAddress(state, selectedAddress){
    state.selectedAddress = selectedAddress;
  },
  updateProtectionMainQuoteRef(state, mainQuoteRef){
    state.mainQuoteRef = mainQuoteRef;
  },
  updateProtectionMainRqQuoteRef(state, mainRqQuoteRef){
    state.mainRqQuoteRef = mainRqQuoteRef;
  },
  updateProtectionQuoteRef(state){
    state.quoteApplication.quoteRefId = state.mainQuoteRef;
    state.quoteApplication.rqQuoteRefId = state.mainRqQuoteRef;
  }
};

const getters = {
  protectionPolicyOwner: state => {
    return (state.quoteForm.primaryMember.firstName || "[Unknown]")+" "+(state.quoteForm.primaryMember.lastName || "[Unknown]");
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};