import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css'; 
import 'quill/dist/quill.bubble.css'; 

Vue.use(VueQuillEditor);

import Quill from 'quill'
const AlignStyle = Quill.import('attributors/style/align');

Quill.register(AlignStyle, true);