import ApiService from "@/core/services/api.service";

const USER_SETTING_ENDPOINT = "/api/v1/user/user-setting";
const EMAIL_SETTING_ENDPOINT = "/api/v1/user/email-setting";
const INSURER_SETTING_ENDPOINT = "/api/v1/user/insurer-setting";
const GENERAL_SETTING_ENDPOINT = "/api/v1/user/general-setting";
const CHECK_VERIFICATION_ENDPOINT = "/api/v1/user/has-text-validation";
const VALIDATE_OTP_ENDPOINT = "/api/v1/user/validate-code";
const TEST_EMAIL_ENDPOINT = "/api/v1/user/email-test";

const state = {
  userSetting: {
    emailNotifications: []
  }
}

const actions = {
  updateUserSetting({ commit }) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(USER_SETTING_ENDPOINT, state.userSetting)
        .then(res => {
          resolve(res.data.success);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserSetting({ commit }) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(USER_SETTING_ENDPOINT)
        .then(res => {
          commit("updateUserSetting", res.data.userSetting);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateEmailSetting({ commit }, emailSetting) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(EMAIL_SETTING_ENDPOINT, emailSetting)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  testEmailSetting({ commit }, email){
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(TEST_EMAIL_ENDPOINT, email)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteEmailSetting({ commit }, emailSettingId){
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(EMAIL_SETTING_ENDPOINT, emailSettingId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateInsurerSetting({ commit }, insurerSetting) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(INSURER_SETTING_ENDPOINT, insurerSetting)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateGeneralSetting({ commit }, generalSetting) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(GENERAL_SETTING_ENDPOINT, generalSetting)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  hasVerification(context, data) {
    var authState = context.rootState.auth;

    return new Promise((resolve, reject) => {
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.post(CHECK_VERIFICATION_ENDPOINT, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verifyOtp(context, data){
    var authState = context.rootState.auth;

    return new Promise((resolve, reject) => {
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.post(VALIDATE_OTP_ENDPOINT, { otp: data.otp, reference: data.reference})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

const mutations = {
  updateUserSetting(state, userSetting) {
    state.userSetting = userSetting;
  },
  addApp(state) {
    if(state.userSetting.hasOwnProperty("apps")){
      state.userSetting.apps.push({
        identifier: "",
        appKey: "",
        formType: "pmi",
        domains: [],
        pushLeads: false,
        crmEndpoint: "none",
        crmApiKey: "",
        customTags: [],
        colorSetting: null
      });
    } else {
      state.userSetting["apps"] = [{
        identifier: "",
        appKey: "",
        domains: [],
        pushLeads: false,
        crmEndpoint: "none",
        crmApiKey: "",
        customTags: [],
        colorSetting: null
      }];
    }
  },
  removeApp(state, appIndex){
    state.userSetting.apps.splice(appIndex, 1);
  }
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters
};