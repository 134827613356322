import ApiService from "@/core/services/api.service";

const moment = require('moment');
const querystring = require('querystring');

const QUOTE_ENDPOINT = "/api/v1/pmi/quote";
const APPLY_ENDPOINT = "/api/v1/pmi/apply";
const POSTCODE_ADDRESS_ENDPOINT = "/api/v1/pmi/postcode-address";

let compare = (a1, a2) => a1.reduce((a, c) => a + a2.includes(c), 0);

const getDefaultState = () => {
  return {
    mainQuoteRef: "",
    editQuoteRef: "",
    mainRqQuoteRef: "",
    editRqQuoteRef: "",
    displayMode: "live",
    isLoading: false,
    isEditMode: false,
    isViewMode: false,
    sortOrder: 'asc',
    sortBy: 'price',
    quoteForm: {
      coverage: "basic",
      excessLevel: 0,
      coverStartDate: moment().format("YYYY-MM-DD"),
      hospital: "countrywide",
      hasDependents: 0,
      underwriting: "NMORI",
      gpRegistered: "From birth",
      primaryMember: {
        title: "mr",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        yearsInsured: 2,
        yearsClaimFree: 0,
        numberOfClaims: 0,
        telephoneNumber: "",
        landlineNumber: "",
        email: "",
        postcode: "",
        address1: "",
        address2: "",
        town: "",
        county: "",
        isSmoker: 0
      },
      dependentMembers: [],
      hasDiagnosedIllness: 0,
      hasTreatment: 0,
      hasPreviousCheckup: 0,
      hasExistingPolicy: 0,
      avivaHospitalRate: "standard",
      vitalityHospitalRate: "local",
      treatment: 0,
      psychiatric: 0,
      dental: 0,
      bupaDental: 0,
      dheLevel: 20,
      bupaTherapy: 0,
      sixWeek: 0,
      guidedOption: 0,
      healthQuestions: {
       bloodDisorder: 0,
       nerveDisorder: 0,
       cancer: 0,
       cardiacVascularDisorder: 0,
       connectiveTissueDisorder: 0,
       dentalDisorder: 0,
       eentDisorder: 0,
       gastroIntestinalDisorder: 0,
       gynaecologicalDisorder: 0,
       urinaryDisorder: 0,
       liverDisorder: 0,
       mentalDisorder: 0,
       endocrineDisorder: 0,
       musculoskeletalDisorder: 0,
       backProblems: 0,
       respiratoryDisorder: 0,
       skinDisorder: 0,
       sensoryDisorder: 0
      }
    },
    quoteResults: [],
    quoteErrors: [],
    selectedProduct: {},
    quoteApplicationStep: "agreement",
    quotePaymentStep: "confirm",
    quoteApplication: {
      rqQuoteRefId: "",
      quoteRefId: "",
      productRefId: "",
      hasReadQuote: false,
      confirmNoAdvice: false,
      hasApplicationDetails: false, 
      isInformationCorrect: false,
      accountName: "",
      accountNumber: "",
      sortCode: ""
    },
    applicationDetails: {
      primaryMember: {
        firstName: "",
        lastName: ""
      },
      coverage: "basic",
      underwriting: "NMORI",
      hospital: "local",
      coverStartDate: moment().format("YYYY-MM-DD")
    },
    addresses: [],
    selectedAddress: {},
  }
}

const state = getDefaultState();

const actions = {
  getQuoteProducts(context) {
    return new Promise((resolve, reject) => {
      if(state.displayMode == "demo"){
        var quotes = require('../../../../demoData/products.json');
        resolve();
        context.commit("updateMainQuoteRef", "demo123456");
        context.commit("updateMainRqQuoteRef","1234566789")
        context.commit("updateQuoteResult", quotes);
        context.commit("updateQuoteErrors", []);
      } else {

        var authState = context.rootState.auth;

        ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
        ApiService.post(QUOTE_ENDPOINT, state.quoteForm)
          .then(res => {
            let quotes = res.data.quoteResult.map(quote => {
              var excessOptions = [];
              var selectedPrice = 0;
              
              for(const [key, value] of Object.entries(quote.price)){
                if(value != 0 && key != "_id"){
                  if(excessOptions.length == 0){
                    selectedPrice = value.toFixed(2);
                  }

                  if(key.replace("excess","") == state.quoteForm.excessLevel && value != 0){
                    selectedPrice = value.toFixed(2);
                  }

                  excessOptions.push({
                    text: key.replace("excess",""),
                    value: value.toFixed(2)
                  });
                }
              }

              var coverDetails = {};

              quote.coverDetails.forEach(function(cover){
                coverDetails[cover.coverType] = cover;
              });

              var opLevel = "No Outpatient";
              if(coverDetails.outpatient.covered){
                if(coverDetails.outpatient.level == "Limited"){
                  if(coverDetails.outpatient.amount){
                    opLevel = coverDetails.outpatient.amount + " Outpatient";
                  } else {
                    opLevel = "Limited Outpatient";
                  }
                } else if(coverDetails.outpatient.level == "Full") {
                  opLevel = "Full Outpatient";
                }
              }

              // Override
              if(coverDetails.outpatient.level == "Not Applicable"){
                coverDetails.outpatient.level = "No Outpatient"
              }

              var additionalOptions = {
                isActive: false,
                excessOptions: excessOptions,
                selectedPrice: selectedPrice,
                coverDetails: coverDetails,
                opLevel: opLevel
              };
              
              switch(state.quoteForm.underwriting){
                case "NMORI":
                  additionalOptions['underwritingLabel'] = "Standard Moratorium";
                  break;
                case "CMORI":
                  additionalOptions['underwritingLabel'] = "Continued Moratorium";
                  break;
                case "CPME":
                  additionalOptions['underwritingLabel'] = "Switch";
                  break;
                case "FMU":
                  additionalOptions['underwritingLabel'] = "Full Medical Underwriting";
                  break;
                default:
                  additionalOptions['underwritingLabel'] = "";
                  break;
              }

              

              return Object.assign({}, quote, additionalOptions);
            });
            
            quotes = quotes.sort((a,b) => {
              if(state.sortBy === 'price'){
                if(state.sortOrder === 'asc'){
                  return a.selectedPrice - b.selectedPrice;
                } else {
                  return b.selectedPrice - a.selectedPrice;
                }
              } else {
                if(state.sortOrder === 'asc'){
                  if(a.companyName > b.companyName){
                    return 1;
                  } else if(a.companyName < b.companyName) {
                    return -1;
                  } else {
                    return 0;
                  }
                } else {
                  if(a.companyName > b.companyName){
                    return -1;
                  } else if(a.companyName < b.companyName) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              }
            });

            context.commit("updateMainQuoteRef", res.data.quoteRef);
            context.commit("updateMainRqQuoteRef", res.data.rqQuoteRef);
            context.commit("updateQuoteResult", quotes);
            context.commit("updateQuoteErrors", res.data.quoteErrors);
            
            resolve(quotes);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      }
    });
  },
  getQuoteProduct(context, params){
    return new Promise((resolve, reject) => {
      if(state.displayMode == "demo"){
        var quote = require('../../../../demoData/product.json');
        context.commit("updateSelectedProduct", quote);
        resolve();
      } else {
        var authState = context.rootState.auth;

        ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
        ApiService.post(QUOTE_ENDPOINT+"/"+state.selectedProduct.providerId, params)
          .then(res => {
            if(res.data.quoteResult.length > 0){
              let quotes = res.data.quoteResult.map(quote => {
                var excessOptions = [];
                var selectedPrice = 0;
                
                for(const [key, value] of Object.entries(quote.price)){
                  if(value != 0 && key != "_id"){
                    if(excessOptions.length == 0){
                      selectedPrice = value.toFixed(2);
                    }
  
                    if(key.replace("excess","") == state.quoteForm.excessLevel && value != 0){
                      selectedPrice = value.toFixed(2);
                    }
  
                    excessOptions.push({
                      text: key.replace("excess",""),
                      value: value.toFixed(2)
                    });
                  }
                }
  
                var coverDetails = {};
  
                quote.coverDetails.forEach(function(cover){
                  coverDetails[cover.coverType] = cover;
                });
  
                var opLevel = "";
                if(coverDetails.outpatient.covered){
                  if(coverDetails.outpatient.level == "Limited"){
                    if(coverDetails.outpatient.amount){
                      opLevel = coverDetails.outpatient.amount + " Outpatient";
                    } else {
                      opLevel = "Limited Outpatient";
                    }
                  } else if(coverDetails.outpatient.level == "Full") {
                    opLevel = "Full Outpatient";
                  } else {
                    opLevel = "No Outpatient"
                  }
                }
  
                var additionalOptions = {
                  isActive: false,
                  excessOptions: excessOptions,
                  selectedPrice: selectedPrice,
                  coverDetails: coverDetails,
                  opLevel: opLevel
                };
                
                switch(params.underwriting){
                  case "NMORI":
                    additionalOptions['underwritingLabel'] = "Standard Moratorium";
                    break;
                  case "CMORI":
                    additionalOptions['underwritingLabel'] = "Continued Moratorium";
                    break;
                  case "CPME":
                    additionalOptions['underwritingLabel'] = "Switch";
                    break;
                  case "FMU":
                    additionalOptions['underwritingLabel'] = "Full Medical Underwriting";
                    break;
                  default:
                    additionalOptions['underwritingLabel'] = "";
                    break;
                }
  
                return Object.assign(quote, additionalOptions);
              });
              
              var matchedQuote = quotes[0];
              var numberOfMatches = 0;

              if(quotes.length == 1){
                matchedQuote = quotes[0];
              } else {
                quotes.forEach(quote => {
                  if(state.selectedProduct.product == quote.product){
                    matchedQuote = quote;
                  } else {
                    var currentStrings = state.selectedProduct.product.split(" ");
                    var newStrings = quote.product.split(" ");

                    var numMatch = compare(currentStrings, newStrings);

                    if(numMatch > numberOfMatches){
                      numberOfMatches = numMatch;
                      matchedQuote = quote;
                    }
                  }
                });
              }
              
              context.commit("updateEditQuoteRef", res.data.quoteRef);
              context.commit("updateEditRqQuoteRef", res.data.rqQuoteRef);
              context.commit("updateSelectedProduct", matchedQuote);
            }
            resolve(matchedQuote);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      }
      
    });
  },
  applyProduct() {
    return new Promise((resolve, reject) => {
      if(state.displayMode == "demo"){
        resolve({succes:true});
      } else {
        var applicationData = {
          applicationDetails: state.applicationDetails,
          quoteApplication: Object.assign({}, state.quoteApplication, {
            selectedPrice: state.selectedProduct.selectedPrice, 
            selectedExcess: state.selectedProduct.selectedExcess
          })
        }

        ApiService.post(APPLY_ENDPOINT, applicationData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      }
      
    });
  },
  getPostcodeAddress(context) {
    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      var params = { postcode: state.quoteForm.primaryMember.postcode }
      
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(POSTCODE_ADDRESS_ENDPOINT, "?"+querystring.encode(params))
        .then(res => {
          context.commit("updateAddresses", res.data.addresses);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getPreviousQuote(context, rqQuoteRef) {
    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      var params = { rqQuoteRef: rqQuoteRef };

      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(QUOTE_ENDPOINT, "?"+querystring.encode(params))
        .then(res => {
          
            
          
          let quotes = res.data.results.map(quote => {
            var excessOptions = [];
            var selectedPrice = 0;
            
            for(const [key, value] of Object.entries(quote.price)){
              if(value != 0 && key != "_id"){
                if(excessOptions.length == 0){
                  selectedPrice = value.toFixed(2);
                }

                if(key.replace("excess","") == state.quoteForm.excessLevel && value != 0){
                  selectedPrice = value.toFixed(2);
                }

                excessOptions.push({
                  text: key.replace("excess",""),
                  value: value.toFixed(2)
                });
              }
            }

            var coverDetails = {};

            quote.coverDetails.forEach(function(cover){
              coverDetails[cover.coverType] = cover;
            });

            var opLevel = "No Outpatient";
            if(coverDetails.outpatient.covered){
              if(coverDetails.outpatient.level == "Limited"){
                if(coverDetails.outpatient.amount){
                  opLevel = coverDetails.outpatient.amount + " Outpatient";
                } else {
                  opLevel = "Limited Outpatient";
                }
              } else if(coverDetails.outpatient.level == "Full") {
                opLevel = "Full Outpatient";
              }
            }

            // Override
            if(coverDetails.outpatient.level == "Not Applicable"){
              coverDetails.outpatient.level = "No Outpatient"
            }

            var additionalOptions = {
              isActive: false,
              excessOptions: excessOptions,
              selectedPrice: selectedPrice,
              coverDetails: coverDetails,
              opLevel: opLevel
            };
            
            switch(state.quoteForm.underwriting){
              case "NMORI":
                additionalOptions['underwritingLabel'] = "Standard Moratorium";
                break;
              case "CMORI":
                additionalOptions['underwritingLabel'] = "Continued Moratorium";
                break;
              case "CPME":
                additionalOptions['underwritingLabel'] = "Switch";
                break;
              case "FMU":
                additionalOptions['underwritingLabel'] = "Full Medical Underwriting";
                break;
              default:
                additionalOptions['underwritingLabel'] = "";
                break;
            }

            

            return Object.assign({}, quote, additionalOptions);
          });
          
          quotes = quotes.sort((a,b) => {
            if(state.sortBy === 'price'){
              if(state.sortOrder === 'asc'){
                return a.selectedPrice - b.selectedPrice;
              } else {
                return b.selectedPrice - a.selectedPrice;
              }
            } else {
              if(state.sortOrder === 'asc'){
                if(a.companyName > b.companyName){
                  return 1;
                } else if(a.companyName < b.companyName) {
                  return -1;
                } else {
                  return 0;
                }
              } else {
                if(a.companyName > b.companyName){
                  return -1;
                } else if(a.companyName < b.companyName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            }
          });
          
          context.commit("updateMainQuoteRef", res.data.quoteRef);
          context.commit("updateMainRqQuoteRef", res.data.rqQuoteRef);
          context.commit("updateQuoteResult", quotes);

          // Update quote
          var quoteForm = res.data.quote;
          quoteForm.coverStartDate = moment(quoteForm.coverStartDate).format("YYYY-MM-DD");
          quoteForm.dateQuoted = moment(quoteForm.dateQuoted).format("YYYY-MM-DD");
          quoteForm.primaryMember.dateOfBirth = moment(quoteForm.primaryMember.dateOfBirth).format("YYYY-MM-DD");

          quoteForm.dependentMembers = quoteForm.dependentMembers.map(member => {
            member.dateOfBirth = moment(member.dateOfBirth).format("YYYY-MM-DD");
            return member;
          });

          context.commit("updateQuoteForm", quoteForm);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
};

const mutations = {
  changeExcess(state){
    var quoteResults = state.quoteResults;
    var excessLevel = state.quoteForm.excessLevel
    var excesses = [0,100,150,200,250,300,500,750,1000,2000];
    
    var quotes = quoteResults.map(quote => {
      if(quote.price["excess"+excessLevel] !== 0){
        quote.selectedPrice = quote.price["excess"+excessLevel].toFixed(2);
      } else {
        // Find closest
        let i,x;
        var hasMatched = false;
        var startIndex = excesses.findIndex(j => j == excessLevel);
        var highIndex = 9;
        var lowIndex = 0;

        if(startIndex != -1){
          //Check high
          for(i = startIndex; i <= highIndex; i++){
            x = excesses[i];

            if(quote.price["excess"+x] !== 0){
              quote.selectedPrice = quote.price["excess"+x].toFixed(2);
              hasMatched = true;
              break;
            }
          }

          // Check low
          if(!hasMatched){
            for(i = startIndex; i >= lowIndex; i--){
              x = excesses[i];
  
              if(quote.price["excess"+x] !== 0){
                quote.selectedPrice = quote.price["excess"+x].toFixed(2);
                hasMatched = true;
                break;
              }
            }
          }
        }
      }
      return quote;
    });

    state.quoteResults = quotes;
  },
  updateHospitalRate(state, hospitalRate){
    state.quoteForm.hospital = hospitalRate;
    if(hospitalRate == 'local'){
      state.quoteForm.avivaHospitalRate = 'standard';
      state.quoteForm.vitalityHospitalRate = 'local';
    } else if(hospitalRate == 'countrywide'){
      state.quoteForm.avivaHospitalRate = 'standard';
      state.quoteForm.vitalityHospitalRate = 'standard';
    } else if(hospitalRate == 'countrywide_plus'){
      state.quoteForm.avivaHospitalRate = 'extended';
      state.quoteForm.vitalityHospitalRate = 'extended';
    }
  },
  updateQuoteForm(state, quoteForm) {
    state.quoteForm = quoteForm;
  },
  updateQuoteResult(state, quoteResult) {
    state.quoteResults = quoteResult;
  },
  updateLoadingStatus(state, loadingStatus){
    state.isLoading = loadingStatus;
  },
  updateEditMode(state, editMode){
    state.isEditMode = editMode;
  },
  updateViewMode(state, viewMode){
    state.isViewMode = viewMode;
  },
  updateQuoteErrors(state, quoteErrors){
    state.quoteErrors = quoteErrors;
  },
  updateSelectedProduct(state, product){
    state.quoteApplicationStep = "agreement";
    state.quotePaymentStep = "confirm";
    state.quoteApplication.hasReadQuote = false;
    state.quoteApplication.confirmNoAdvice = false;
    state.quoteApplication.hasApplicationDetails = false;
    state.quoteApplication.isInformationCorrect = false;
    state.quoteApplication.accountName = "";
    state.quoteApplication.accountNumber = "";
    state.quoteApplication.sortCode = "";
    state.selectedProduct = product;
    state.quoteApplication.productRefId = product._id;
  },
  updateQuoteApplication(state, quoteApplication){
    state.quoteApplication = quoteApplication;
  },
  addMember(state){
    state.quoteForm.dependentMembers.push({
      title: "mr",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      relationship: "spouse",
      isSmoker: 0,
      yearsInsured: 2,
      yearsClaimFree: 0,
      numberOfClaims: 0
    });
  },
  removeMember(state, memberIndex){
    state.quoteForm.dependentMembers.splice(memberIndex, 1);
  },
  updateHealthQuestion(state, healthQuestions){
    state.quoteForm.healthQuestions = healthQuestions;
  },
  resetHealthQuestion(state){
    for (const key of Object.entries(state.quoteForm.healthQuestions)) {
      state.quoteForm.healthQuestions[key] = 0;
    }
  },
  updateApplicationStep(state, step){
    state.quoteApplicationStep = step;
  },
  updatePaymentStep(state, step){
    state.quotePaymentStep = step;
  },
  updateApplicationDetails(state, applicationDetails){
    state.applicationDetails = applicationDetails;
  },
  resetState(state){
    Object.assign(state, getDefaultState());
  },
  updateDisplayMode(state, displayMode){
    state.displayMode = displayMode;
  },
  updateSortBy(state, sortBy){
    state.sortBy = sortBy;
  },
  updateSortOrder(state, sortOrder){
    state.sortOrder = sortOrder;
  },
  updateHasApplicationDetails(state, hasApplicationDetails){
    state.quoteApplication.hasApplicationDetails = hasApplicationDetails;
  },
  sortQuotes(state){
    state.quoteResults = state.quoteResults.sort((a,b) => {
      if(state.sortBy === 'price'){
        if(state.sortOrder === 'asc'){
          return a.selectedPrice - b.selectedPrice;
        } else {
          return b.selectedPrice - a.selectedPrice;
        }
      } else {
        if(state.sortOrder === 'asc'){
          if(a.companyName > b.companyName){
            return 1;
          } else if(a.companyName < b.companyName) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if(a.companyName > b.companyName){
            return -1;
          } else if(a.companyName < b.companyName) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    });
  },
  updateAddresses(state, addresses){
    state.addresses = addresses;
  },
  updateSelectedAddress(state, selectedAddress){
    state.selectedAddress = selectedAddress;
  },
  updateMainQuoteRef(state, mainQuoteRef){
    state.mainQuoteRef = mainQuoteRef;
  },
  updateMainRqQuoteRef(state, mainRqQuoteRef){
    state.mainRqQuoteRef = mainRqQuoteRef;
  },
  updateEditQuoteRef(state, editQuoteRef){
    state.editQuoteRef = editQuoteRef;
  },
  updateEditRqQuoteRef(state, editRqQuoteRef){
    state.editRqQuoteRef = editRqQuoteRef;
  },
  updateQuoteRef(state, type){
    if(type == "main"){
      state.quoteApplication.quoteRefId = state.mainQuoteRef;
      state.quoteApplication.rqQuoteRefId = state.mainRqQuoteRef;
    } else {
      state.quoteApplication.quoteRefId = state.editQuoteRef;
      state.quoteApplication.rqQuoteRefId = state.editRqQuoteRef;
    }
  },
  updateMainToEditRef(state){
    state.editQuoteRef = state.mainQuoteRef;
    state.editRqQuoteRef = state.mainRqQuoteRef;
  }
};

const getters = {
  policyOwner: state => {
    return (state.quoteForm.primaryMember.firstName || "[Unknown]")+" "+(state.quoteForm.primaryMember.lastName || "[Unknown]");
  },
  underwritingLabel: state => {
    switch(state.quoteForm.underwriting){
      case "NMORI":
        return "Standard Moratorium";
      case "CMORI":
        return "Continued Moratorium";
      case "CPME":
        return "Switch";
      case "FMU":
        return "Full Medical Underwriting";
      default:
        return "";
    }
  },
  coverageLabel: state => {
    let coverage = state.quoteForm.coverage;
    return coverage.replace(/^./, coverage.charAt(0).toUpperCase());
  },
  applicationPolicyOwner: state => {
    return (state.applicationDetails.primaryMember.firstName || "[Unknown]")+" "+(state.applicationDetails.primaryMember.lastName || "[Unknown]");
  },
  applicationHospitalLabel: state => { 
    return state.selectedProduct.selectedHospital;
  },
  applicationUnderwritingLabel: state => {
    switch(state.applicationDetails.underwriting){
      case "NMORI":
        return "Standard Moratorium";
      case "CMORI":
        return "Continued Moratorium";
      case "CPME":
        return "Switch";
      case "FMU":
        return "Full Medical Underwriting";
      default:
        return "";
    }
  },
  applicationCoverageLabel: state => {
    let coverage = state.selectedProduct.coverageLevel;
    if(coverage == 1){
      return "Budget"
    } else if(coverage == 3){
      return "Fully Comprehensive";
    } else {
      return "Mid-Range"
    }
  },
  applicationExcessLabel: state => {
    return "£"+state.selectedProduct.selectedExcess;
  },
  applicationChoosenStartDate: state => {
    return moment(state.selectedProduct.coverStartDate).format("Do MMMM YYYY");
  },
  coverFor: state => {
    if(state.quoteForm.dependentMembers.length == 0){
      return "You";
    } else {
      var hasSpouse = false;
      var hasChildren = false;

      state.quoteForm.dependentMembers.forEach(member => {
        if(member.relationship == 'spouse'){
          hasSpouse = true;
        }

        if(member.relationship == 'son' || member.relationship == 'daughter'){
          hasChildren = true;
        }
      });
      
      if(hasSpouse && hasChildren){
        return "Family";
      } else if(hasSpouse){
        return "Couple";
      } else if(hasChildren){
        return "Single-Parent";
      } else {
        return "You";
      }
    }
    
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};