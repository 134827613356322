import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


library.add(faAngleDown);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false

