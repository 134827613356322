import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import pmi from "./pmi.module";
import leads from "./leads.module";
import formDesign from "./formDesign.module";
import userSetting from "./userSetting.module";
import protection from "./protection.module";
import protectionLeads from "./protectionLeads.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    pmi,
    leads,
    formDesign,
    userSetting,
    protection,
    protectionLeads
  }
});
