import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const VALIDATE_FORM = "validateForm";
export const SSO_LOGIN = "fetchAccessToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_VALIDATED = "setValidated";
export const SET_PLUGIN_CREDENTIALS = "setPluginCredentials";
export const SET_OAUTH_STATE = "setOAuthState";

const LOGIN_URL = "/api/v1/user/login";
const VERIFY_URL = "/api/v1/user/verify";
const VALIDATE_URL = "/api/v1/user/validate";
const OAUTH_TOKEN_URL = "/api/v1/user/oauth";

const state = {
  isValidated: false,
  pluginCredentials: {},
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  oAuthState: ""
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isValidated(state) {
    return state.isValidated;
  },
  getFullName(state){
    var firstName = state.user.userObj.firstName;
    var lastName = state.user.userObj.lastName;
    return firstName+" "+lastName;
  },
  getUserInfo(state){
    return state.user.userObj || {"photo": ""};
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve,reject) => {
      ApiService.post(LOGIN_URL, credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(VERIFY_URL)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [VALIDATE_FORM](context, payload){  
    return new Promise(resolve => {
      const displayMode = context.rootState.pmi.displayMode;

      if(displayMode == "demo"){
        context.commit(SET_PLUGIN_CREDENTIALS, { appKey: "", domain: "" });
        context.commit(SET_VALIDATED, true);
        resolve();
      } else {
        ApiService.setPluginHeader(payload.appKey, payload.domain);
        ApiService.post(VALIDATE_URL)
        .then(res => {
          if(res.data.success){
            context.commit(SET_PLUGIN_CREDENTIALS, payload);
          }
          context.commit(SET_VALIDATED, res.data.success);    
          resolve(res.data);
        });
      }
    });
  },
  [SSO_LOGIN](context, payload){
    return new Promise((resolve, reject) => {
      ApiService.post(OAUTH_TOKEN_URL, payload)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);

          console.log(data.userObj);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};

    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_PLUGIN_CREDENTIALS](state, pluginCredentials){
    state.pluginCredentials = pluginCredentials;
  },
  [SET_VALIDATED](state, isValidated){
    state.isValidated = isValidated;
  },
  [SET_OAUTH_STATE](state, oAuthState){
    state.oAuthState = oAuthState;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
