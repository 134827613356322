import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import { phone } from 'phone';

Validator.extend('uk-mobile', {
  getMessage: field => 'The ' +field+ ' value is not in correct format.',
  validate: value => {
    
    var result = phone(value, { country: 'GB', strictDetection: true });
    console.log(result);
    return result.isValid;
  }
});


Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors'
});