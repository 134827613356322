import Vue from 'vue';
import _ from "lodash";

const yearsInsured = function(years){
  switch(years){
      case 2:
          return '1 year';
      case 3:
          return '2-3 years';
      case 4:
          return '3-4 years';
      case 5:
          return '4-5 years';
      case 6:
          return '5 years or more';
      default:
          return '';
  }
}

const yearsClaimFree = function(years){
  switch(years){
      case 0:
          return 'Less than 1 year';
      case 1:
          return '1 year';
      case 2:
          return '2-3 years';
      case 3:
          return '3-4 years';
      case 4:
          return '4-5 years';
      case 5:
          return '5 years or more';
      default:
          return '';
  }
}

const RqHelper = {
  install(Vue, options) {
    console.log(options);
    
    var lastHeight;
    var lastWidth;

    Vue.prototype.$recordFrame = () => {
      console.log("Record frame height");
      var body = document.body,
      html = document.documentElement;
      lastHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      lastWidth = document.body.offsetWidth;
    }

    Vue.prototype.$resizeFrame = (delay = 500) => {
      setTimeout(function(){
        console.log("Resize frame");

        var body = document.body,
        html = document.documentElement;
        var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        var width = document.body.offsetWidth;

        // Set actual size
        var pluginMessage = { 
          messageType: "resize",
          payload: {
            width: width,
            height: height
          } 
        };
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");
      }, delay);
    }

    Vue.prototype.$resetFrame = () => {
      console.log("Reset frame");
      window.parent.postMessage(JSON.stringify({messageType: "resetSize"}),"*");
    }

    Vue.prototype.$revertHeight = (delay = 500) => {
      if(lastHeight && lastWidth){
        setTimeout(function(){
          console.log("Revert frame");

          var pluginMessage = { 
            messageType: "resize",
            payload: {
              width: lastWidth,
              height: lastHeight
            } 
          };
          window.parent.postMessage(JSON.stringify(pluginMessage),"*");
        }, delay);
      }
    }

    Vue.prototype.$formatPmi = (quoteForm) => {
      const booleanKeys = ['bupaDental','bupaTherapy','dental','guidedOption','hasDiagnosedIllness','hasExistingPolicy','hasPreviousCheckup','hasTreatment','psychiatric','sixWeek','treatment','isSmoker'];
      var modifiedForm = Object.assign({}, _.cloneDeep(quoteForm));

      delete modifiedForm.healthQuestions;
      
      console.log(quoteForm);
      // Convert 1/0 to true/false
      for(const [key, value]  of Object.entries(modifiedForm)){
        if(booleanKeys.includes(key)){
          modifiedForm[key] = value == 1 
        }
      }

      // Clean up
      delete modifiedForm.hasDependents;

      // Primary member
      for(const [key, value]  of Object.entries(modifiedForm.primaryMember)){
        if(booleanKeys.includes(key)){
          modifiedForm.primaryMember[key] = value == 1
        }
      }

      if(modifiedForm.hasExistingPolicy){
        if(modifiedForm.primaryMember.numberOfClaims){
          modifiedForm.primaryMember.numberOfClaims = '3+';
        }
        modifiedForm.primaryMember.yearsInsured = yearsInsured(modifiedForm.primaryMember.yearsInsured);
        modifiedForm.primaryMember.yearsClaimFree = yearsClaimFree(modifiedForm.primaryMember.yearsClaimFree);
      } else {
        delete modifiedForm.primaryMember.numberOfClaims;
        delete modifiedForm.primaryMember.yearsClaimFree;
        delete modifiedForm.primaryMember.yearsInsured;
      }

      // Dependents
      for(var index = 0; index < modifiedForm.dependentMembers.length; index++){
        var member = modifiedForm.dependentMembers[index];
        for(const [key, value]  of Object.entries(member)){
          if(booleanKeys.includes(key)){
            member[key] = value == 1
          }
        }

        if(modifiedForm.hasExistingPolicy){
          if(member.numberOfClaims){
            member.numberOfClaims = '3+';
          }
          member.yearsInsured = yearsInsured(member.yearsInsured);
          member.yearsClaimFree = yearsClaimFree(member.yearsClaimFree);
        } else {
          delete member.numberOfClaims;
          delete member.yearsClaimFree;
          delete member.yearsInsured;
        }

        modifiedForm.dependentMembers[index] = member;
      }
      console.log(modifiedForm);

      return modifiedForm;
    }

    Vue.prototype.$formatProtection = (quoteForm) => {
      console.log(quoteForm);
    }
  }
}

Vue.use(RqHelper)