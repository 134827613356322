import ApiService from "@/core/services/api.service";

const querystring = require('querystring');
const moment = require('moment');

const QUOTES_ENDPOINT = "/api/v1/pmi/quotes";
const QUOTE_ENDPOINT = "/api/v1/pmi/quote";
const INSURERS_ENDPOINT = "/api/v1/pmi/insurers";

const state = {
  quotes: [],
  pagination: {
    totalDocs: 0,
    limit: 3,
    totalPages: 0,
    page: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: 2
  },
  insurers: [],
  sale: {
    insurerId: "",
    planName: "",
    premium: "",
    paymentMode: "monthly",
    coverStartDate: moment().format("YYYY-MM-DD")
  }
}

const actions = {
  getQuotes: function({ commit }, params){
    return new Promise((resolve, reject) => {
      ApiService.get(QUOTES_ENDPOINT, "?"+querystring.encode(params))
        .then(res => {
          commit("updatePagination", {
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            totalPages: res.data.totalPages,
            page: res.data.page,
            pagingCounter: res.data.pagingCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage
          });
          commit("updateQuotes", res.data.docs);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  updateQuote: function({ commit }, payload){
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.update(QUOTE_ENDPOINT, payload.quoteId, payload.quote)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getInsurers: function({ commit }){
    ApiService.get(INSURERS_ENDPOINT)
      .then(res => {
        if(res.data.success){
          commit("updateInsurers", res.data.insurers);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
}

const mutations = {
  updateQuotes(state, quotes) {
    state.quotes = quotes;
  },
  updatePagination(state, pagination) {
    state.pagination = pagination;
  },
  updateInsurers(state, insurers) {
    state.insurers = insurers;
  },
  updateSale(state, sale) {
    state.sale = sale;
  }
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters
};